import React, { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import Link from '~/components/Link'

const LinkWrapper = styled.span`
	&::before,
	&::after {
		content: '-';
	}

	& + & {
		margin-left: 20px;
	}
`

interface Props {
	locale: string
}

const Menu: FunctionComponent<Props> = ({ locale, ...rest }) => {
	const isEnglish = locale === 'en'
	// const localePrefix = isEnglish ? '' : '/nl'

	return (
		<Box {...rest}>
			<LinkWrapper>
				<Link href="https://www.linkedin.com/in/gfokke/" rel="noopener">
					linkedin
				</Link>
			</LinkWrapper>
			<LinkWrapper>{isEnglish ? <Link to="/nl/">nederlands</Link> : <Link to="/">english</Link>}</LinkWrapper>
		</Box>
	)
}

export default Menu
