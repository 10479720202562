// fix styled-components `css` usage for TypeScript
// @see: https://styled-components.com/docs/api#usage-with-typescript
/// <reference types="styled-components/cssprop" />

import React, { FunctionComponent } from 'react'
import { PageRendererProps, PageProps, graphql } from 'gatsby'
import loadable from '@loadable/component'

import Header from '~/components/Header'
import LayoutRoot from '~/components/Layout/LayoutRoot'
import LayoutMain from '~/components/Layout/LayoutMain'
import Head from '~/components/Head'
import { ContentfulMetaData } from '~/types/graphql'

const ParticlesBackground = loadable(() => import(/* webpackPrefetch: true */ '~/components/ParticlesBackground'))

export const query = graphql`
	fragment LayoutData on Query {
		contentfulMetaData(contentful_id: { eq: "1kAu0c2pq3Fm1BZguHu7O0" }, node_locale: { eq: $locale }) {
			title
			keywords
			description
			openGraphTitle
			image {
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
					}
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`

export type LayoutDataProps = {
	pageContext: { locale: string }
	data?: {
		contentfulMetaData: ContentfulMetaData
		site: {
			siteMetadata: {
				siteUrl: string
			}
		}
	}
}

type LayoutProps = PageRendererProps & LayoutDataProps

const Layout: FunctionComponent<LayoutProps> = ({ children, data, location, pageContext }) => {
	const { locale } = pageContext
	const {
		contentfulMetaData,
		site: {
			siteMetadata: { siteUrl }
		}
	} = data || { contentfulMeta: { description: {}, keywords: [] }, site: { siteMetadata: {} } }

	return (
		<LayoutRoot>
			<Head lang={locale} contentfulMetaData={contentfulMetaData} {...{ siteUrl, location }} />
			<ParticlesBackground update={location.pathname} />
			<Header locale={locale} />
			<LayoutMain>{children}</LayoutMain>
			{/* <Footer data={data} /> */}
		</LayoutRoot>
	)
}

export default Layout
