import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'
import { PageRendererProps } from 'gatsby'
import { globalCss, FontSpaceMonoWoff2 } from '~/styles/global'
import { ContentfulMetaData } from '~/types/graphql'

type HeadProps = {
	contentfulMetaData: ContentfulMetaData
	siteUrl: string
} & PageRendererProps

const Head: FunctionComponent<HeadProps> = ({
	contentfulMetaData: { title, description, keywords, openGraphTitle, image },
	siteUrl,
	location,
	lang
}) => (
	<Helmet
		htmlAttributes={{
			lang
		}}
	>
		<title>{title}</title>
		<meta name="description" content={description} />
		<meta name="keywords" content={(keywords || []).join()} />
		<link rel="canonical" href={`${siteUrl}${location.pathname}`} />
		{/* fonts */}
		{/* autocode font is inline atm as it is very small */}
		{/* <link rel="preload" href={FontAutocodeWoff2} as="font" type="font/woff2" crossOrigin="anonymous" /> */}
		<link rel="preload" href={FontSpaceMonoWoff2} as="font" type="font/woff2" crossOrigin="anonymous" />
		{/* start of favicon */}
		<link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
		<link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
		<link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
		<link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
		<link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
		<link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
		<link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
		<link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
		<link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
		<link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
		<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
		<link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
		<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
		<link rel="manifest" href="/manifest.json" />,
		<meta name="msapplication-TileColor" content="#ffffff" />,
		<meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
		<meta name="theme-color" content="#ffffff" />
		{/* end of favicon */}
		<meta property="og:title" content={openGraphTitle} />
		<meta property="og:type" content="website" />
		<meta property="og:url" content={siteUrl} />
		<meta property="og:image" content={`${siteUrl.replace(/^https?:/gi, 'http:')}${image.localFile.publicURL}`} />
		<meta property="og:image:secure_url" content={`${siteUrl}${image.localFile.publicURL}`} />
		<meta property="og:image:type" content="image/jpeg" />
		<meta property="og:image:width" content={image.localFile.childImageSharp.original.width} />
		<meta property="og:image:height" content={image.localFile.childImageSharp.original.height} />
		<style>{globalCss.join('')}</style>
	</Helmet>
)

export default Head
