import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles'
import color from 'color'

import { responseStylesToCSS, Styles } from './helpers'

const colorPrimary = '#304FFE'

export const fontFamily = {
	logo: 'autocodew01-regular, "Autocode Regular", Autocode, "Trebuchet MS", serif',
	primary: 'space_mono, "Space Mono", "Trebuchet MS", serif',
	secondary: 'space_mono, "Space Mono", "Trebuchet MS", serif'
}

// Strip any fallback fonts during development so it is very obvious if fonts are not loading properly
// TODO: ENV not available
if (process.env.GATSBY_ACTIVE_ENV === 'development') {
	fontFamily.logo = fontFamily.logo.replace(/,.*$/, ', serif')
	fontFamily.primary = fontFamily.primary.replace(/,.*$/, ', serif')
	fontFamily.secondary = fontFamily.secondary.replace(/,.*$/, ', serif')
}

// Tell Material-UI what's the font-size on the html element .
// 16px is the default font-size used by browsers.
const htmlFontSize = 16
export const pxToRem = (px: number) => `${px / htmlFontSize}rem`

/**
 * Tweak the default MUI settings here
 * @see: https://material-ui.com/customization/default-theme/
 */
export const overridings: ThemeOptions = {
	palette: {
		primary: {
			main: colorPrimary,
			contrastText: '#fff'
		},
		secondary: {
			main: '#333',
			contrastText: '#fff'
		}
	},
	typography: {
		fontFamily: fontFamily.secondary,
		h1: {
			fontFamily: fontFamily.primary
		},
		h2: {
			fontFamily: fontFamily.primary
		},
		h3: {
			fontFamily: fontFamily.primary
		},
		h4: {
			fontFamily: fontFamily.primary
		},
		h5: {
			fontFamily: fontFamily.primary
		},
		h6: {
			fontFamily: fontFamily.primary
		},
		body1: {
			fontFamily: fontFamily.secondary,
			fontSize: pxToRem(16)
		},
		button: {
			fontFamily: fontFamily.primary,
			fontSize: pxToRem(27)
		}
	}
}

const muiTheme = createMuiTheme(overridings)

export default muiTheme

const themeBreakpoints: string[] = muiTheme.breakpoints.keys.map((key) => `${muiTheme.breakpoints.values[key]}px`)

export const themeResponseStylesToCSS = (styles: Styles) => responseStylesToCSS(styles, themeBreakpoints)
