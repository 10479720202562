import React, { FunctionComponent, ReactNode } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import Menu from '~/components/Menu'
import Logo from '~/components/Logo'

export interface FooterDataProps {
	locale: string
}

const Header: FunctionComponent<FooterDataProps> = ({ locale }: Props) => (
	<Box textAlign="center" pt={5} pb={2}>
		<Logo component="h1" />
		<Box mt={3}>
			<Menu locale={locale} />
		</Box>
	</Box>
)

export default Header
