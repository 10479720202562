import React, { FunctionComponent } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { css } from 'styled-components'

import muiTheme from '~/styles/mui-theme'

interface LayoutRootProps {
	className?: string
}

const LayoutRoot: FunctionComponent<LayoutRootProps> = ({ children }) => (
	<div
		css={css`
			position: relative;
			overflow: hidden;
			padding-bottom: 50px;
			min-height: 100vh;
		`}
	>
		<MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
	</div>
)

export default LayoutRoot
