/* file name can not be changed due to Gatsby's API */
/* eslint-disable react/jsx-filename-extension */

import React from 'react'
import Layout from './src/components/Layout'

/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

/**
 * Ensures the Layout and Page receive the same props.
 * Also ensures the Layout component does not re-render / remount on navigation.
 *
 * @see: https://github.com/gatsbyjs/gatsby/issues/6127
 * @see: https://www.gatsbyjs.org/docs/ssr-apis/#wrapPageElement
 * @see: https://www.gatsbyjs.org/packages/gatsby-plugin-layout/
 */
export const wrapPageElement = ({ element, props }) => {
	// props provide same data to Layout as Page element will get
	// including location, data, etc - you don't need to pass it
	return <Layout {...props}>{element}</Layout>
}
