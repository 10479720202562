import { css } from 'styled-components'

import muiTheme from '~/styles/mui-theme'

import FontSpaceMonoWoff from '~/content/fonts/space-mono/subset/spacemono-regular-webfont.woff'
import FontSpaceMonoWoff2 from '~/content/fonts/space-mono/subset/spacemono-regular-webfont.woff2'

import FontAutocodeWoff from '~/content/fonts/autocode/autocode_w01_regular-webfont.woff'
import FontAutocodeWoff2 from '~/content/fonts/autocode/autocode_w01_regular-webfont.woff2'

export { FontSpaceMonoWoff2, FontAutocodeWoff2 }

// Default export does not work with storybook for some reason
export const globalCss = css`
	@font-face {
		font-family: 'space_mono';
		src: url(${FontSpaceMonoWoff2}) format('woff2'), url(${FontSpaceMonoWoff}) format('woff');
		font-weight: normal;
		font-style: normal;
		font-display: block;
	}

	@font-face {
		font-family: 'autocodew01-regular';
		src: url(${FontAutocodeWoff2}) format('woff2'), url(${FontAutocodeWoff}) format('woff');
		font-weight: normal;
		font-style: normal;
		font-display: block;
	}

	html {
		box-sizing: border-box;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		overflow-x: hidden;
	}
	*,
	*::before,
	*::after {
		box-sizing: inherit;
	}
	strong,
	b {
		font-weight: 700;
	}
	body {
		color: rgba(0, 0, 0, 0.87);
		margin: 0;
		font-weight: 400;
		line-height: 1.43;
		background-color: #fafafa;
		width: 100%;
		overflow-x: hidden;
	}
	@media print {
		body {
			background-color: #fff;
		}
	}
	body::backdrop {
		background-color: #fafafa;
	}

	body,
	p {
		font-family: ${muiTheme.typography.fontFamily};
		font-size: ${muiTheme.typography.body1.fontSize};
	}
	h1,
	h2,
	h3 {
		&.MuiTypography-root:before,
		&.MuiTypography-root:after {
			content: '-';
		}
	}
`
