import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import { pxToRem, fontFamily } from '~/styles/mui-theme'
import { ease } from '~/styles/theme/ease'

const logoHeight = 51

const StyledTypography = styled(Typography)`
	font-family: ${fontFamily.logo};
	font-size: ${pxToRem(36)};
	white-space: nowrap;

	z-index: 2;
	text-size-adjust: none;
	-webkit-text-size-adjust: none;

	display: inline-block;
	position: relative;
	font-size: ${pxToRem(34)};
	padding: 0px 5px 2px 6px;
	/* overflow: hidden; */
	-webkit-backface-visibility: hidden;

	&:before {
		content: '';
		background-color: #1a1a1a;
		display: block;
		position: absolute;
		z-index: 0;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		-webkit-backface-visibility: hidden;

		animation: 0.75s ${ease.quartOut} 0.5s scale forwards;
		/* transform: scaleY(0); */
		transform: scale3D(1, 0, 1) translateZ(0);
		@keyframes scale {
			from {
				/* transform: scaleY(0); */
				transform: scale3D(1, 0, 1) translateZ(0);
			}
			to {
				/* transform: scaleY(1); */
				transform: scale3D(1, 1, 1) translateZ(0);
			}
		}
	}
`
const flickerSteps = [0.2, 0, 0.5, 0, 0.2, 0, 0.7, 0, 0.9, 0.2, 0.8, 0.4, 0.9, 0.3, 1]
const stepSizes = [0, 15, 30, 45, 55, 65, 70, 85, 88, 90, 92, 95, 97, 99, 100]
const stepsLength = stepSizes.length

const StyledLogo = styled.span`
	display: inline-block;
	position: relative;
	top: -0.06em;

	color: #efefef;
	animation: 0.8s ${ease.quartOut} 0.4s flicker forwards;
	opacity: 0;

	@keyframes flicker {
		${flickerSteps
			.reduce((acc, opacity, index) => {
				const isFirst = index === 0
				const isLast = index === stepsLength - 1
				const stepSize = isFirst ? stepSizes[0] : stepSizes[index] - stepSizes[index - 1]
				const lastStep = isFirst ? null : acc[index - 1]

				acc.push({
					from: lastStep ? lastStep.to + 1 : 0,
					to: isLast ? 100 : lastStep ? lastStep.to + stepSize : stepSize,
					opacity
				})

				return acc
			}, [])
			.reduce((acc, step) => {
				return `${acc}
			${step.from}%,
			${step.to}% { opacity:${step.opacity} }`
			}, '')}
	}
`

interface Props {
	component: string
}

const Logo = ({ component = 'div' }: Props) => (
	<StyledTypography component={component}>
		<StyledLogo>grt-dev</StyledLogo>
	</StyledTypography>
)

export default Logo
